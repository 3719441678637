import XLSX, { read } from 'xlsx'

function downloadTables(filename, tables) {
    const wb = XLSX.utils.book_new()
    tables.forEach((table) => {
        const values = table.values.map((e) => [...e])
        const ws = XLSX.utils.aoa_to_sheet([table.headers, ...values])
        XLSX.utils.book_append_sheet(wb, ws, table.label)
    })
    XLSX.writeFile(wb, `${filename}.xlsb`)
}

function workbookToJSON(workbook) {
    const result = {}
    workbook.SheetNames.forEach(function (sheetName) {
        const roa = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
            header: 1,
            blankrows: false
        })
        if (roa.length) {
            result[sheetName] = roa
        }
    })
    return JSON.stringify(result, 2, 2)
}

function processExcel(data) {
    const workbook = XLSX.read(data, {
        type: `binary`
    })

    // eslint-disable-next-line no-unused-vars
    const _ = workbook.SheetNames[0]
    return workbookToJSON(workbook)
}

function convertExcelFileToJson(file, callback) {
    const reader = new FileReader()
    reader.onload = (progressEvent) => {
        const workbook = XLSX.read(progressEvent.target.result, {
            type: 'binary'
        })
        if (callback) {
            callback(
                XLSX.utils.sheet_to_json(
                    workbook.Sheets[workbook.SheetNames[0]]
                )
            )
        }
    }
    reader.readAsBinaryString(file)
}

function downloadJsonAsExcel(filename, bookname, json) {
    const binary = XLSX.utils.json_to_sheet(json)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, binary, bookname)
    XLSX.writeFile(workbook, `${filename}.xlsb`)
}

export {
    downloadTables,
    processExcel,
    workbookToJSON,
    downloadJsonAsExcel,
    convertExcelFileToJson
}
