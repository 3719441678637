<template>
    <div
        class="container-bg"
        style="
            margin: auto;
            display: block;
            background: rgba(20, 20, 20, 0.8);
            width: 100%;
        "
    >
        <div class="container-fg">
            <v-card dark style="background-color: transparent" elevation="0">
                <v-card-title style="background-color: transparent">
                    <h1
                        style="
                            font-size: 36px;
                            letter-spacing: -0.08rem;
                            color: white;
                        "
                    >
                        Organization Information Required
                    </h1>
                </v-card-title>
                <v-card-text
                    ><h3
                        >You must register organization information in order to
                        use this feature.</h3
                    >
                </v-card-text>
                <v-divider style="margin-bottom: 10px" color="gray"></v-divider>
                <div style="padding: 15px">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="orgName"
                                label="Enter Organization Name"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <v-col cols="5" style="display: block; margin: auto">
                        <v-btn
                            style="display: block; margin: auto"
                            color="primary"
                            outlined
                            :disabled="!orgName"
                            @click="registerOrg"
                            >Register</v-btn
                        >
                    </v-col>
                </div>
            </v-card>
        </div>
        <v-snackbar v-model="snackbar">
            {{ snackbarMessage }}

            <template v-slot:action="{ attrs }">
                <v-btn
                    text
                    v-bind="attrs"
                    color="white"
                    @click="snackbar = false"
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>

<script>
import gql from 'graphql-tag'

export default {
    name: 'OrgOverlay',
    props: {},
    data() {
        return {
            orgName: '',
            snackbar: false,
            snackbarMessage: ''
        }
    },
    methods: {
        registerOrg() {
            this.$apollo
                .mutate({
                    mutation: gql`
                        mutation UpdateOrg($orgName: String!) {
                            updateOrg(orgName: $orgName)
                        }
                    `,
                    variables: {
                        orgName: this.orgName
                    }
                })
                .then((data) => {
                    if (data.data.updateOrg === 'success') {
                        this.snackbar = true
                        this.snackbarMessage =
                            `Successfully registered ` + this.orgName
                        window.location.reload()
                    } else {
                        this.snackbar = true
                        this.snackbarMessage =
                            'Error registering organization:' +
                            data.data.updateOrg.error
                    }
                })
                .catch((error) => {
                    this.snackbar = true
                    this.snackbarMessage =
                        'Error registering organization:' + error
                })
        }
    }
}
</script>
